<template>
  <div class="tw-p-6 md:tw-p-10 tw-drop-shadow-md tw-border-b-2">
    <div class="tw-flex tw-items-center tw-justify-between tw-mb-6">
      <router-link
        :to="{ name: 'categories' }"
        class="text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900">
        <i class="mr-2 pi pi-arrow-left"></i>
        Kembali
      </router-link>
      <Button label="Edit Kategori" 
        icon="pi pi-pencil" 
        iconPos="left" 
        class="tw-h-11 tw-text-sm tw-mr-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300" 
        @click="$router.push({
          name: 'edit-category',
          params: {id: id},
        })"
      />
    </div>
    <h5 class="tw-text-xl inter-semibold tw-text-black">
      Detail Kategori
    </h5>
  </div>

  <div class="tw-p-6 md:tw-p-10">
    <div class="card tw-mb-10">
      <div class="grid">
        <div class="col-12 sm:col-5 md:col-12 lg:col-4">
          <h3 class="mb-3 tw-text-lg inter-semibold">Foto</h3>
          <div class="grid">
            <div class="col-12 text-center">
              <div class="photo" :style="{'backgroundImage': `url(${data.image})`}"></div>
            </div>
          </div>  
        </div>

        <div class="col-12 sm:col-6 md:col-12 lg:col-7 sm:col-offset-1 md:col-offset-0 lg:col-offset-1">
          <div class="grid sm:tw-pt-10">
            <div class="col-12 lg:col-4">
              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Nama Kategori
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ data.name }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: ['id'],
  data() {
    return {
      data: {},
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData() {
      this.$http
        .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/categories/${this.id}`)
        .then(response => {
          this.data = response.data.data
        })
    },

  },
}
</script>

<style lang="scss" scoped>
.photo {
  width: 100%;
  height: 240px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}
</style>
